import _defineProperty from "@babel/runtime/helpers/defineProperty";
import "./scss/index.scss";
import * as React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as S from "@components/organisms/ProductList/styles";
import { ProductTile } from "@components/molecules";
import * as HCS from "@components/molecules/Home/index";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import { generateCategoryUrl } from "@utils/core";
import nophoto from "images/nophoto.png";
import { ProductsFeatured } from "../../components";
import { generateProductUrl, generateCollectionUrl } from "../../core/utils";
import { structuredData } from "../../core/SEO/Homepage/structuredData";
import noPhotoImg from "../../images/no-photo.svg";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css"; // install Swiper modules

SwiperCore.use([Autoplay, Pagination, Navigation]);

var Page = function Page(_ref) {
  var shop = _ref.shop,
      slider_menu = _ref.slider_menu,
      home_menu = _ref.home_menu,
      second_slider_menu = _ref.second_slider_menu;

  var homeMenuExist = function homeMenuExist() {
    return home_menu && home_menu.items && home_menu.items.length > 0;
  };

  var sliderExist = function sliderExist() {
    return slider_menu && slider_menu.items && slider_menu.items.length > 0;
  };

  var secondSliderExist = function secondSliderExist() {
    return second_slider_menu && second_slider_menu.items && second_slider_menu.items.length > 0;
  };

  var intl = useIntl();

  var goToPrevSlide = function goToPrevSlide(swiper) {
    var currentIndex = swiper.activeIndex;
    var prevIndex = currentIndex - 1;

    if (prevIndex >= 0) {
      swiper.slideTo(prevIndex);
    } else {
      // If the swiper is already on the first slide, go to the last slide
      swiper.slideTo(swiper.slides.length - 1);
    }
  };

  return React.createElement(React.Fragment, null, React.createElement(Helmet, null, React.createElement("title", null, "FUH Online")), React.createElement("script", {
    className: "structured-data-list",
    type: "application/ld+json"
  }, structuredData(shop)), sliderExist() && React.createElement(Swiper, {
    className: "home-page__hero-swiper",
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    pagination: {
      clickable: true
    }
  }, slider_menu && slider_menu.items.map(function (_ref2) {
    var slider = _ref2.collection;
    return React.createElement("div", null, React.createElement(SwiperSlide, {
      className: "home-page__hero-swiper-swiper-slide"
    }, React.createElement(Link, {
      to: generateCollectionUrl(slider.id, slider.name),
      key: slider.id
    }, React.createElement("img", {
      className: "home-page__hero-swiper-swiper-slide-swiper-image",
      src: slider && slider.backgroundImage ? slider.backgroundImage.url : noPhotoImg,
      alt: slider.id
    }))));
  })), React.createElement("div", null, React.createElement("h1", {
    style: {
      display: "none"
    }
  }, "Home")), secondSliderExist() && React.createElement("div", {
    className: "category"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement(Swiper, {
    className: "category-swiper",
    slidesPerView: 4,
    spaceBetween: 40,
    breakpoints: {
      0: {
        slidesPerView: 1
      },
      550: {
        slidesPerView: 2
      },
      820: {
        slidesPerView: 3
      },
      1080: {
        slidesPerView: 4
      }
    },
    onTransitionStart: function onTransitionStart(swiper) {
      var wrapperEl = swiper.wrapperEl;

      if (wrapperEl) {
        var _swiper$getTranslate = swiper.getTranslate(),
            translate = _swiper$getTranslate.translate,
            previousTranslate = _swiper$getTranslate.previousTranslate;

        var isDirectionRight = translate > previousTranslate;

        if (swiper.activeIndex > swiper.previousIndex) {
          wrapperEl.style.transform = isDirectionRight ? "translate3d(0px, 0px, 0px)" : window.innerWidth >= 490 ? "translate3d(-43vw, 0px, 0px)" : window.innerWidth >= 437 ? "translate3d(-63vw, 0px, 0px)" : window.innerWidth <= 380 ? "translate3d(-68vw, 0px, 0px)" : window.innerWidth <= 393 ? "translate3d(-67vw, 0px, 0px)" : window.innerWidth >= 394 ? "translate3d(-68vw, 0px, 0px)" : "translate3d(-80vw, 0px, 0px)"; // Add this statement to modify the padding of .container

          var container = document.querySelector(".container");
          container.style.padding = "0";
        } else {
          wrapperEl.style.transform = "translate3d(0px, 0px, 0px)";

          var _container = document.querySelector(".container");

          _container.style.padding = "0 0 0 1rem";
        }
      }
    },
    onTouchStart: function onTouchStart(swiper, event) {
      swiper.startX = event.touches[0].pageX;
      swiper.startY = event.touches[0].pageY;
    },
    onTouchMove: function onTouchMove(swiper, event) {
      swiper.currentX = event.touches[0].pageX;
      swiper.currentY = event.touches[0].pageY;
    },
    onTouchEnd: function onTouchEnd(swiper) {
      var wrapperEl = swiper.wrapperEl;

      if (wrapperEl) {
        var _window$getComputedSt = window.getComputedStyle(wrapperEl),
            transform = _window$getComputedSt.transform;

        var matrix = transform.match(/^matrix\((.+)\)$/);
        var currentTranslate = matrix ? parseFloat(matrix[1].split(", ")[4]) : 0;
        var previousTranslate = swiper.previousTranslate;

        if (currentTranslate === previousTranslate) {
          return;
        }

        var isDirectionRight = currentTranslate > previousTranslate;
        wrapperEl.style.transform = isDirectionRight ? "translate3d(0px, 0px, 0px)" : "translate3d(0px, 0px, 0px)";
        swiper.previousTranslate = currentTranslate;
        var isSwipingHorizontally = Math.abs(swiper.currentX - swiper.startX) > Math.abs(swiper.currentY - swiper.startY);

        if (isDirectionRight && isSwipingHorizontally && swiper.currentX - swiper.startX > 100) {
          goToPrevSlide(swiper);
        }
      }
    }
  }, second_slider_menu && second_slider_menu.items.map(function (menu, index) {
    var _menu$category = menu.category,
        id = _menu$category.id,
        name = _menu$category.name,
        backgroundImage = _menu$category.backgroundImage;
    return React.createElement("div", null, React.createElement(SwiperSlide, {
      className: "category-swiper-swiper-slide",
      key: index
    }, React.createElement(Link, {
      to: generateCategoryUrl(id, name),
      key: index
    }, backgroundImage ? React.createElement("img", {
      className: "category-swiper-swiper-slide-swiper-image",
      src: backgroundImage === null || backgroundImage === void 0 ? void 0 : backgroundImage.url,
      alt: id
    }) : React.createElement("img", {
      className: "category-swiper-swiper-slide-swiper-noimage",
      src: nophoto,
      alt: id
    }))));
  })))), React.createElement(ProductsFeatured, {
    title: intl.formatMessage(_defineProperty({
      defaultMessage: "Featured",
      "id": "views.Home.Page.2812648978"
    }, "id", "views.Home.Page.2812648978"))
  }), homeMenuExist() && home_menu.items.map(function (menu_item) {
    return React.createElement(HCS.HomeCategory, null, React.createElement("div", {
      className: "category"
    }, React.createElement("div", {
      className: "container"
    }, React.createElement("div", {
      className: "home-page__product-collection-title"
    }, React.createElement(Link, {
      to: generateCollectionUrl(menu_item.collection.id, menu_item.collection.name),
      key: menu_item.collection.id
    }, React.createElement("h2", null, menu_item.collection.translation && menu_item.collection.translation.name || menu_item.collection.name)), React.createElement(Link, {
      to: generateCollectionUrl(menu_item.collection.id, menu_item.collection.name),
      key: menu_item.collection.id
    }, React.createElement("div", {
      style: {
        display: "flex"
      }
    }, intl.formatMessage(_defineProperty({
      defaultMessage: "VIEW ALL",
      "id": "views.Home.Page.3861097584"
    }, "id", "views.Home.Page.3861097584")), React.createElement("div", {
      style: {
        marginLeft: "10px"
      }
    }, React.createElement("svg", {
      width: "7",
      height: "11",
      viewBox: "0 0 7 11",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg"
    }, React.createElement("path", {
      d: "M1 1L5.5 5.5L1 10",
      stroke: "#323232",
      strokeWidth: "1.5"
    })))))), React.createElement(S.List, {
      "data-test": "productList",
      "data-test-id": "homeMenuProducts"
    }, menu_item.collection.products.edges.map(function (edge) {
      var _edge$node = edge.node,
          id = _edge$node.id,
          name = _edge$node.name;
      return id && name && React.createElement(Link, {
        to: generateProductUrl(id, name),
        key: id
      }, React.createElement(ProductTile, {
        product: edge.node
      }));
    })))));
  }));
};

export default Page;