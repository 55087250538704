import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import * as React from "react";
import { Link } from "react-router-dom";
import { generateCategoryUrl, generateCollectionUrl, generatePageUrl } from "../../core/utils";
export var NavLink = function NavLink(_ref) {
  var item = _ref.item,
      props = _objectWithoutProperties(_ref, ["item"]);

  var url = item.url,
      category = item.category,
      collection = item.collection,
      page = item.page;
  var navName = "";

  if (category) {
    var _category$translation;

    navName = category.translation && ((_category$translation = category.translation) === null || _category$translation === void 0 ? void 0 : _category$translation.name) ? category.translation.name : category.name;
  } else if (collection) {
    var _collection$translati;

    navName = collection.translation && ((_collection$translati = collection.translation) === null || _collection$translati === void 0 ? void 0 : _collection$translati.name) ? collection.translation.name : collection.name;
  } else if (page) {
    var _page$translation;

    navName = page.translation && ((_page$translation = page.translation) === null || _page$translation === void 0 ? void 0 : _page$translation.title) ? page.translation.title : page.title;
  } else {
    var _item$translation;

    navName = item.translation && ((_item$translation = item.translation) === null || _item$translation === void 0 ? void 0 : _item$translation.name) ? item.translation.name : item.name;
  }

  var link = function link(url) {
    return React.createElement(Link, _extends({
      to: url
    }, props), navName);
  };

  if (url && url.includes("fuhonline.com")) {
    return React.createElement("a", _extends({
      href: url
    }, props), navName);
  }

  if (url && !url.includes("fuhonline.com")) {
    return React.createElement("a", _extends({
      target: "_blank",
      rel: "noreferrer noopener nofollow",
      href: url
    }, props), navName);
  }

  if (category) {
    var _category$translation2;

    return link(generateCategoryUrl(category.id, category.translation && ((_category$translation2 = category.translation) === null || _category$translation2 === void 0 ? void 0 : _category$translation2.name) ? category.translation.name : category.name));
  }

  if (collection) {
    var _collection$translati2;

    return link(generateCollectionUrl(collection.id, collection.translation && ((_collection$translati2 = collection.translation) === null || _collection$translati2 === void 0 ? void 0 : _collection$translati2.name) ? collection.translation.name : collection.name));
  }

  if (page) {
    return link(generatePageUrl(page.slug));
  }

  return React.createElement("span", props, navName);
};