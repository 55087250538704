import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  query FeaturedProducts($languageCode: LanguageCodeEnum!) {\n    shop {\n      homepageCollection {\n        id\n        products(first: 20) {\n          edges {\n            node {\n              ...BasicProductFields\n              ...ProductPricingField\n              category {\n                id\n                name\n                translation(languageCode: $languageCode) {\n                  name\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
import { basicProductFragment, productPricingFragment } from "../../views/Product/queries";
export var featuredProducts = gql(_templateObject(), basicProductFragment, productPricingFragment);
export var TypedFeaturedProductsQuery = TypedQuery(featuredProducts);