import "./scss/index.scss";
import isEqual from "lodash/isEqual";
import * as React from "react";
import { Thumbnail } from "@components/molecules";
import { TaxedMoney } from "../../@next/components/containers";

var ProductListItem = function ProductListItem(_ref) {
  var _product$pricing, _product$pricing$pric, _product$pricing2, _product$pricing2$pri;

  var product = _ref.product;
  var category = product.category;
  var price = (_product$pricing = product.pricing) === null || _product$pricing === void 0 ? void 0 : (_product$pricing$pric = _product$pricing.priceRange) === null || _product$pricing$pric === void 0 ? void 0 : _product$pricing$pric.start;
  var priceUndiscounted = (_product$pricing2 = product.pricing) === null || _product$pricing2 === void 0 ? void 0 : (_product$pricing2$pri = _product$pricing2.priceRangeUndiscounted) === null || _product$pricing2$pri === void 0 ? void 0 : _product$pricing2$pri.start;

  var getProductPrice = function getProductPrice() {
    if (isEqual(price, priceUndiscounted)) {
      return React.createElement(TaxedMoney, {
        taxedMoney: price
      });
    }

    return React.createElement(React.Fragment, null, React.createElement("span", {
      className: "product-list-item__undiscounted_price"
    }, React.createElement(TaxedMoney, {
      taxedMoney: priceUndiscounted
    })), React.createElement(TaxedMoney, {
      taxedMoney: price
    }));
  };

  return React.createElement("div", {
    className: "product-list-item"
  }, React.createElement("div", {
    className: "product-list-item__image"
  }, React.createElement(Thumbnail, {
    source: product
  })), React.createElement("h4", {
    className: "product-list-item__title"
  }, product.translation && product.translation.name || product.name), React.createElement("p", {
    className: "product-list-item__category"
  }, category && category.translation && category.translation.name || (category === null || category === void 0 ? void 0 : category.name)), React.createElement("p", {
    className: "product-list-item__price"
  }, getProductPrice()));
};

export default ProductListItem;