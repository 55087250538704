import "./scss/index.scss";
import * as React from "react"; // import { SocialMediaIcon } from "..";
// import { SOCIAL_MEDIA } from "../../core/config";

import Nav from "./Nav";

var Footer = function Footer() {
  return React.createElement("div", {
    className: "footer",
    id: "footer"
  }, React.createElement(Nav, null));
};

export default Footer;