import * as React from "react";
import { RichTextContent } from "@components/atoms";
import * as S from "@components/molecules/ProductDescription/styles";
import { Breadcrumbs } from "../../components";
export var Page = function Page(_ref) {
  var _page$translation;

  var breadcrumbs = _ref.breadcrumbs,
      headerImage = _ref.headerImage,
      navigation = _ref.navigation,
      page = _ref.page;
  return React.createElement("div", {
    className: "article-page"
  }, React.createElement("div", {
    className: "article-page__header",
    style: headerImage ? {
      backgroundImage: "url(".concat(headerImage, ")")
    } : null
  }, React.createElement("span", {
    className: "article-page__header__title"
  }, React.createElement("h1", null, page.translation && ((_page$translation = page.translation) === null || _page$translation === void 0 ? void 0 : _page$translation.title) ? page.translation.title : page.title))), React.createElement("div", {
    className: "container"
  }, React.createElement(Breadcrumbs, {
    breadcrumbs: breadcrumbs
  }), React.createElement("div", {
    className: "article-page__container"
  }, React.createElement("div", {
    className: "article-page__content"
  }, React.createElement(S.Description, null, React.createElement(RichTextContent, {
    descriptionJson: page.translation && page.translation.contentJson || page.contentJson
  }))))));
};
export default Page;