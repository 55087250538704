import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _extends from "@babel/runtime/helpers/extends";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { InputSelect, TextField } from "@components/molecules";
import { commonMessages } from "@temp/intl";
import * as S from "./styles";
export var AddressFormContent = function AddressFormContent(_ref) {
  var formRef = _ref.formRef,
      handleChange = _ref.handleChange,
      handleBlur = _ref.handleBlur,
      formId = _ref.formId,
      errors = _ref.errors,
      handleSubmit = _ref.handleSubmit,
      values = _ref.values,
      countriesOptions = _ref.countriesOptions,
      statesOptions = _ref.statesOptions,
      defaultCountry = _ref.defaultCountry,
      defaultState = _ref.defaultState,
      setFieldValue = _ref.setFieldValue,
      testingContext = _ref.testingContext,
      _ref$includeEmail = _ref.includeEmail,
      includeEmail = _ref$includeEmail === void 0 ? false : _ref$includeEmail;
  var basicInputProps = useCallback(function () {
    return {
      onBlur: handleBlur,
      onChange: handleChange
    };
  }, [handleChange, handleBlur]);

  function isValidName(name) {
    return /[@_!#$%^&*()<>?/\|}{~:0123456789]/.test(name);
  }

  function isValidEmail(email) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
  }

  function existsInErrors(field_name) {
    return errors.findIndex(function (error) {
      return error.field === field_name;
    }) !== -1;
  }

  function removeErrorInErrors(field_name) {
    // eslint-disable-next-line func-names
    errors = errors.filter(function (error) {
      return error.field !== field_name;
    });
  }

  var intl = useIntl();
  var fieldErrors = {}; // Check whether email is valid or not

  if ((values === null || values === void 0 ? void 0 : values.email) && !isValidEmail(values === null || values === void 0 ? void 0 : values.email)) {
    removeErrorInErrors("email");
    errors.push({
      code: "NOTVALID",
      field: "email",
      message: "Enter a valid email address",
      __typename: "CheckoutError"
    });
  } else if ((values === null || values === void 0 ? void 0 : values.email) && isValidEmail(values === null || values === void 0 ? void 0 : values.email) && existsInErrors("email")) {
    removeErrorInErrors("email");
  } // Check whether first name is valid or not


  if ((values === null || values === void 0 ? void 0 : values.firstName) && isValidName(values === null || values === void 0 ? void 0 : values.firstName)) {
    removeErrorInErrors("firstName");
    errors.push({
      code: "NOTVALID",
      field: "firstName",
      message: "Enter a valid first name",
      __typename: "CheckoutError"
    });
  } else if ((values === null || values === void 0 ? void 0 : values.firstName) && !isValidName(values === null || values === void 0 ? void 0 : values.firstName) && existsInErrors("firstName")) {
    removeErrorInErrors("firstName");
  } // Check whether last name is valid or not


  if ((values === null || values === void 0 ? void 0 : values.lastName) && isValidName(values === null || values === void 0 ? void 0 : values.lastName)) {
    removeErrorInErrors("lastName");
    errors.push({
      code: "NOTVALID",
      field: "lastName",
      message: "Enter a valid last name",
      __typename: "CheckoutError"
    });
  } else if ((values === null || values === void 0 ? void 0 : values.lastName) && !isValidName(values === null || values === void 0 ? void 0 : values.lastName) && existsInErrors("lastName")) {
    removeErrorInErrors("lastName");
  }

  if (errors) {
    errors.map(function (_ref2) {
      var field = _ref2.field,
          message = _ref2.message;
      fieldErrors[field] = fieldErrors[field] ? [].concat(_toConsumableArray(fieldErrors[field]), [{
        message: message
      }]) : [{
        message: message
      }];
    });
  }

  return React.createElement(S.AddressForm, {
    id: formId,
    ref: formRef,
    onSubmit: handleSubmit,
    "data-test": testingContext
  }, React.createElement(S.Wrapper, null, React.createElement(S.RowWithTwoCells, null, React.createElement(TextField, _extends({
    name: "firstName",
    label: intl.formatMessage(commonMessages.firstName),
    value: values.firstName,
    autoComplete: "given-name",
    errors: fieldErrors.firstName,
    required: true
  }, basicInputProps())), React.createElement(TextField, _extends({
    name: "lastName",
    label: intl.formatMessage(commonMessages.lastName),
    value: values.lastName,
    autoComplete: "family-name",
    errors: fieldErrors.lastName,
    required: true
  }, basicInputProps()))), React.createElement(S.RowWithTwoCells, null, React.createElement(TextField, _extends({
    name: "companyName",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Company Name",
      "id": "@next.components.organisms.AddressForm.AddressFormContent.2953060418"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContent.2953060418")),
    value: values.companyName,
    autoComplete: "organization",
    errors: fieldErrors.companyName
  }, basicInputProps())), React.createElement(TextField, _extends({
    name: "phone",
    label: intl.formatMessage(commonMessages.phoneWithHelpText),
    value: values.phone || "+971 5",
    autoComplete: "tel",
    errors: fieldErrors.phone
  }, basicInputProps()))), React.createElement(S.RowWithOneCell, null, React.createElement(TextField, _extends({
    name: "streetAddress1",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Address line 1",
      "id": "@next.components.organisms.AddressForm.AddressFormContent.1363074570"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContent.1363074570")),
    value: values.streetAddress1,
    autoComplete: "address-line1",
    required: true,
    errors: fieldErrors.streetAddress1
  }, basicInputProps()))), React.createElement(S.RowWithOneCell, null, React.createElement(TextField, _extends({
    name: "streetAddress2",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Address line 2",
      "id": "@next.components.organisms.AddressForm.AddressFormContent.3121963259"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContent.3121963259")),
    value: values.streetAddress2,
    autoComplete: "address-line2",
    errors: fieldErrors.streetAddress2
  }, basicInputProps()))), React.createElement(S.RowWithTwoCells, null, React.createElement(TextField, _extends({
    name: "city",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Area",
      "id": "@next.components.organisms.AddressForm.AddressFormContent.1576912740"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContent.1576912740")),
    value: values.city,
    autoComplete: "address-level2",
    required: true,
    errors: fieldErrors.city
  }, basicInputProps())), React.createElement(TextField, _extends({
    name: "postalCode",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "ZIP/Postal Code",
      "id": "@next.components.organisms.AddressForm.AddressFormContent.2796540622"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContent.2796540622")),
    value: values.postalCode,
    autoComplete: "postal-code",
    errors: fieldErrors.postalCode
  }, basicInputProps()))), React.createElement(S.RowWithTwoCells, null, React.createElement(InputSelect, {
    defaultValue: defaultState,
    name: "countryArea",
    options: statesOptions,
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Emirate *",
      "id": "@next.components.organisms.AddressForm.AddressFormContent.2165133785"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContent.2165133785")),
    value: values.countryArea && statesOptions && statesOptions.find(function (option) {
      return option.code === values.countryArea.code;
    }),
    optionLabelKey: "countryArea",
    optionValueKey: "code",
    onChange: function onChange(value, name) {
      return setFieldValue(name, value);
    },
    autoComplete: "countryArea",
    errors: fieldErrors.countryArea
  }), React.createElement(InputSelect, {
    defaultValue: defaultCountry,
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Country",
      "id": "@next.components.organisms.AddressForm.AddressFormContent.1139500589"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContent.1139500589")),
    name: "country",
    options: countriesOptions,
    value: values.country && countriesOptions && countriesOptions.find(function (option) {
      return option.code === values.country.code;
    }),
    onChange: function onChange(value, name) {
      return setFieldValue(name, value);
    },
    optionLabelKey: "country",
    optionValueKey: "code",
    errors: fieldErrors.country,
    autoComplete: "country"
  })), includeEmail && React.createElement(S.RowWithTwoCells, null, React.createElement(TextField, _extends({
    name: "email",
    label: intl.formatMessage(commonMessages.shortEmail),
    value: values.email,
    autoComplete: "email",
    required: true,
    errors: fieldErrors.email
  }, basicInputProps())))));
};