import * as React from "react";
import { Link } from "react-router-dom";
import { Thumbnail } from "@components/molecules";
import { generateProductUrl } from "../../../core/utils";

var ProductItem = function ProductItem(_ref) {
  var _product$translation, _product$translation2, _product$category$tra;

  var product = _ref.node;
  return React.createElement("li", {
    className: "search__products__item"
  }, React.createElement(Link, {
    to: generateProductUrl(product.id, product.translation && ((_product$translation = product.translation) === null || _product$translation === void 0 ? void 0 : _product$translation.name) || product.name)
  }, React.createElement(Thumbnail, {
    source: product
  }), React.createElement("span", null, React.createElement("h4", null, product.translation && ((_product$translation2 = product.translation) === null || _product$translation2 === void 0 ? void 0 : _product$translation2.name) || product.name), React.createElement("p", null, product.category.translation && ((_product$category$tra = product.category.translation) === null || _product$category$tra === void 0 ? void 0 : _product$category$tra.name) || product.category.name || "-"))));
};

export default ProductItem;