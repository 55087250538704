import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import * as React from "react";
import { useIntl } from "react-intl";
import { prodListHeaderCommonMsg } from "@temp/intl";
import { StringParam, useQueryParam } from "use-query-params";
import { Loader } from "@components/atoms";
import { MetaWrapper, NotFound, OfflinePlaceholder } from "../../components";
import NetworkStatus from "../../components/NetworkStatus";
import { PRODUCTS_PER_PAGE } from "../../core/config";
import { convertSortByFromString, convertToAttributeScalar, getGraphqlIdFromDBId } from "../../core/utils";
import Page from "./Page";
import { TypedCategoryProductsQuery, TypedCategoryProductsDataQuery } from "./queries";
import { useLanguage } from "../../../gqlTypes/globalTypes";
export var FilterQuerySet = {
  encode: function encode(valueObj) {
    var str = [];
    Object.keys(valueObj).forEach(function (value) {
      str.push("".concat(value, "_").concat(valueObj[value].join("_")));
    });
    return str.join(".");
  },
  decode: function decode(strValue) {
    var obj = {};
    var propsWithValues = strValue.split(".").filter(function (n) {
      return n;
    });
    propsWithValues.map(function (value) {
      var propWithValues = value.split("_").filter(function (n) {
        return n;
      });
      obj[propWithValues[0]] = propWithValues.slice(1);
    });
    return obj;
  }
};
export var View = function View(_ref) {
  var match = _ref.match;

  var _useQueryParam = useQueryParam("sortBy", StringParam),
      _useQueryParam2 = _slicedToArray(_useQueryParam, 2),
      sort = _useQueryParam2[0],
      setSort = _useQueryParam2[1];

  var _useQueryParam3 = useQueryParam("filters", FilterQuerySet),
      _useQueryParam4 = _slicedToArray(_useQueryParam3, 2),
      attributeFilters = _useQueryParam4[0],
      setAttributeFilters = _useQueryParam4[1];

  var intl = useIntl();
  var language = useLanguage();

  var clearFilters = function clearFilters() {
    setAttributeFilters({});
  };

  var onFiltersChange = function onFiltersChange(name, value) {
    if (attributeFilters && attributeFilters.hasOwnProperty(name)) {
      if (attributeFilters[name].includes(value)) {
        if (filters.attributes["".concat(name)].length === 1) {
          var att = _objectSpread({}, attributeFilters);

          delete att["".concat(name)];
          setAttributeFilters(_objectSpread({}, att));
        } else {
          setAttributeFilters(_objectSpread({}, attributeFilters, _defineProperty({}, "".concat(name), attributeFilters["".concat(name)].filter(function (item) {
            return item !== value;
          }))));
        }
      } else {
        setAttributeFilters(_objectSpread({}, attributeFilters, _defineProperty({}, "".concat(name), [].concat(_toConsumableArray(attributeFilters["".concat(name)]), [value]))));
      }
    } else {
      setAttributeFilters(_objectSpread({}, attributeFilters, _defineProperty({}, "".concat(name), [value])));
    }
  };

  var filters = {
    attributes: attributeFilters,
    pageSize: PRODUCTS_PER_PAGE,
    priceGte: null,
    priceLte: null,
    sortBy: sort || null
  };

  var variables = _objectSpread({}, filters, {
    attributes: filters.attributes ? convertToAttributeScalar(filters.attributes) : {},
    id: getGraphqlIdFromDBId(match.params.id, "Category"),
    sortBy: convertSortByFromString(filters.sortBy),
    languageCode: language.toUpperCase()
  });

  var sortOptions = [{
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsClear),
    value: null
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsPrice),
    value: "price"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsPriceDsc),
    value: "-price"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsName),
    value: "name"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsNameDsc),
    value: "-name"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsUpdatedAt),
    value: "updated_at"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsUpdatedAtDsc),
    value: "-updated_at"
  }];
  return React.createElement(NetworkStatus, null, function (isOnline) {
    return React.createElement(TypedCategoryProductsDataQuery, {
      variables: variables,
      errorPolicy: "all",
      loaderFull: true
    }, function (categoryData) {
      var _categoryData$data, _categoryData$data$at, _categoryData$data2, _categoryData$data2$c;

      if (categoryData.loading) {
        return React.createElement(Loader, null);
      }

      if (categoryData.data && categoryData.data.category === null) {
        return React.createElement(NotFound, null);
      }

      if (!isOnline) {
        return React.createElement(OfflinePlaceholder, null);
      }

      var canDisplayFilters = !!((_categoryData$data = categoryData.data) === null || _categoryData$data === void 0 ? void 0 : (_categoryData$data$at = _categoryData$data.attributes) === null || _categoryData$data$at === void 0 ? void 0 : _categoryData$data$at.edges) && !!((_categoryData$data2 = categoryData.data) === null || _categoryData$data2 === void 0 ? void 0 : (_categoryData$data2$c = _categoryData$data2.category) === null || _categoryData$data2$c === void 0 ? void 0 : _categoryData$data2$c.name);
      return React.createElement(TypedCategoryProductsQuery, {
        variables: variables
      }, function (categoryProducts) {
        if (!canDisplayFilters && categoryProducts.loading) {
          return React.createElement(Loader, null);
        }

        if (canDisplayFilters) {
          var _categoryProducts$dat, _categoryProducts$dat2;

          var handleLoadMore = function handleLoadMore() {
            return categoryProducts.loadMore(function (prev, next) {
              return _objectSpread({}, prev, {
                products: _objectSpread({}, prev.products, {
                  edges: [].concat(_toConsumableArray(prev.products.edges), _toConsumableArray(next.products.edges)),
                  pageInfo: next.products.pageInfo
                })
              });
            }, {
              after: categoryProducts.data.products.pageInfo.endCursor
            });
          };

          return React.createElement(MetaWrapper, {
            meta: {
              description: categoryData.data.category.translation && categoryData.data.category.translation.seoDescription ? categoryData.data.category.translation.seoDescription : categoryData.data.category.seoDescription,
              title: categoryData.data.category.translation && categoryData.data.category.translation.seoTitle ? categoryData.data.category.translation.seoTitle : categoryData.data.category.seoTitle,
              type: "product.category"
            }
          }, React.createElement(Page, {
            clearFilters: clearFilters,
            attributes: categoryData.data.attributes.edges.map(function (edge) {
              return edge.node;
            }),
            category: categoryData.data.category,
            displayLoader: categoryData.loading,
            hasNextPage: (_categoryProducts$dat = categoryProducts.data) === null || _categoryProducts$dat === void 0 ? void 0 : (_categoryProducts$dat2 = _categoryProducts$dat.products) === null || _categoryProducts$dat2 === void 0 ? void 0 : _categoryProducts$dat2.pageInfo.hasNextPage,
            sortOptions: sortOptions,
            activeSortOption: filters.sortBy,
            filters: filters,
            products: categoryProducts.data.products,
            onAttributeFiltersChange: onFiltersChange,
            onLoadMore: handleLoadMore,
            activeFilters: filters.attributes ? Object.keys(filters.attributes).length : 0,
            onOrder: function onOrder(value) {
              setSort(value.value);
            }
          }));
        }

        return null;
      });
    });
  });
};
export default View;