import _extends from "@babel/runtime/helpers/extends";
import _typeof from "@babel/runtime/helpers/typeof";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
import { useCreateUserAddress, useUpdateUserAddress } from "@saleor/sdk";
import { AddressForm } from "../AddressForm";
import { Modal } from "../Modal";
export var AddressFormModal = function AddressFormModal(_ref) {
  var hideModal = _ref.hideModal,
      submitBtnText = _ref.submitBtnText,
      target = _ref.target,
      title = _ref.title,
      userId = _ref.userId,
      address = _ref.address,
      formId = _ref.formId,
      props = _objectWithoutProperties(_ref, ["hideModal", "submitBtnText", "target", "title", "userId", "address", "formId"]);

  var _React$useState = React.useState(true),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      show = _React$useState2[0],
      setShow = _React$useState2[1];

  var errors = [];

  var _useCreateUserAddress = useCreateUserAddress(),
      _useCreateUserAddress2 = _slicedToArray(_useCreateUserAddress, 2),
      setCreatUserAddress = _useCreateUserAddress2[0],
      _useCreateUserAddress3 = _useCreateUserAddress2[1],
      createData = _useCreateUserAddress3.data,
      addressCreateErrors = _useCreateUserAddress3.error;

  var _useUpdateUserAddress = useUpdateUserAddress(),
      _useUpdateUserAddress2 = _slicedToArray(_useUpdateUserAddress, 2),
      setUpdateUserAddress = _useUpdateUserAddress2[0],
      _useUpdateUserAddress3 = _useUpdateUserAddress2[1],
      updateData = _useUpdateUserAddress3.data,
      addressUpdateErrors = _useUpdateUserAddress3.error;

  var defaultState = {
    countryArea: "Dubai",
    code: "Dubai",
    __typename: "StateDisplay"
  };
  var defaultCountry = {
    __typename: "CountryDisplay",
    code: "AE",
    country: "United Arab Emirates"
  };

  if (addressCreateErrors) {
    errors = addressCreateErrors.extraInfo.userInputErrors;
  }

  if (addressUpdateErrors) {
    errors = addressUpdateErrors.extraInfo.userInputErrors;
  }

  React.useEffect(function () {
    if (createData && !addressCreateErrors || updateData && !addressUpdateErrors) {
      hideModal();
    }
  }, [createData, updateData, addressCreateErrors, addressUpdateErrors]);
  return React.createElement(Modal, {
    submitButtonTestingContext: "submitAddressFormModalButton",
    testingContext: "submitAddressFormModal",
    title: title,
    hide: function hide() {
      hideModal();
      setShow(false);
    },
    formId: formId,
    disabled: false,
    show: show,
    target: target,
    submitBtnText: submitBtnText
  }, React.createElement(AddressForm, _extends({}, props, {
    errors: errors
  }, {
    formId: formId,
    address: address ? address.address : undefined,
    defaultState: defaultState,
    defaultCountry: defaultCountry,
    handleSubmit: function handleSubmit(data) {
      if (userId) {
        var _data$country, _data$countryArea;

        setCreatUserAddress({
          input: _objectSpread({}, data, {
            country: data === null || data === void 0 ? void 0 : (_data$country = data.country) === null || _data$country === void 0 ? void 0 : _data$country.code,
            countryArea: data === null || data === void 0 ? void 0 : (_data$countryArea = data.countryArea) === null || _data$countryArea === void 0 ? void 0 : _data$countryArea.countryArea
          })
        });
      } else {
        var _data$country2, _data$countryArea2;

        setUpdateUserAddress({
          id: address.id,
          input: _objectSpread({}, data, {
            country: data === null || data === void 0 ? void 0 : (_data$country2 = data.country) === null || _data$country2 === void 0 ? void 0 : _data$country2.code,
            countryArea: _typeof(data === null || data === void 0 ? void 0 : data.countryArea) === "object" ? data === null || data === void 0 ? void 0 : (_data$countryArea2 = data.countryArea) === null || _data$countryArea2 === void 0 ? void 0 : _data$countryArea2.countryArea : data === null || data === void 0 ? void 0 : data.countryArea
          })
        });
      }
    }
  })));
};