import React from "react";
import { ErrorMessage, Radio } from "@components/atoms";
import { PROVIDERS } from "@temp/core/config";
import { BraintreePaymentGateway, DummyPaymentGateway, StripePaymentGateway, AdyenPaymentGateway, MashreqPaymentGateway } from "..";
import * as S from "./styles";

/**
 * Payment Gateways list
 */
var PaymentGatewaysList = function PaymentGatewaysList(_ref) {
  var paymentGateways = _ref.paymentGateways,
      selectedPaymentGateway = _ref.selectedPaymentGateway,
      selectedPaymentGatewayToken = _ref.selectedPaymentGatewayToken,
      selectPaymentGateway = _ref.selectPaymentGateway,
      formRef = _ref.formRef,
      formId = _ref.formId,
      _processPayment = _ref.processPayment,
      submitPayment = _ref.submitPayment,
      submitPaymentSuccess = _ref.submitPaymentSuccess,
      errors = _ref.errors,
      onError = _ref.onError;
  return React.createElement(S.Wrapper, null, paymentGateways.map(function (_ref2, index) {
    var id = _ref2.id,
        name = _ref2.name,
        config = _ref2.config;
    var checked = selectedPaymentGateway === id;

    switch (name) {
      case PROVIDERS.BRAINTREE.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayBraintreeInput",
          name: "payment-method",
          value: "credit-card",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayBraintreeName"
        }, name))), checked && React.createElement(BraintreePaymentGateway, {
          config: config,
          formRef: formRef,
          formId: formId,
          processPayment: function processPayment(token, cardData) {
            return _processPayment(id, token, cardData);
          },
          errors: errors,
          onError: onError
        }));

      case PROVIDERS.DUMMY.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayDummyInput",
          name: "payment-method",
          value: "dummy",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayDummyName"
        }, name))), checked && React.createElement(DummyPaymentGateway, {
          formRef: formRef,
          formId: formId,
          processPayment: function processPayment(token) {
            return _processPayment(id, token);
          },
          initialStatus: selectedPaymentGatewayToken
        }));

      case PROVIDERS.MASHREQ.label:
        return React.createElement("div", {
          key: index
        }, React.createElement("h3", {
          "data-test": "checkoutPageSubtitle",
          style: {
            fontWeight: "bold",
            padding: "1.6rem 0px 1.6rem"
          }
        }, "PAYMENT METHOD"), React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayMashreqInput",
          name: "payment-method",
          value: "ccavenue",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayMashreqName"
        }, "CCAvenue"))), checked && React.createElement(MashreqPaymentGateway, {
          formRef: formRef,
          formId: formId,
          processPayment: function processPayment(token) {
            return _processPayment(id, token);
          },
          initialStatus: selectedPaymentGatewayToken
        }), React.createElement("div", {
          style: {
            width: "100%",
            borderBottom: "1px solid rgba(50,50,50,0.1)",
            margin: "30px 0"
          }
        }));

      case PROVIDERS.STRIPE.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayStripeInput",
          name: "payment-method",
          value: "stripe",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayStripeName"
        }, name))), checked && React.createElement(StripePaymentGateway, {
          config: config,
          formRef: formRef,
          formId: formId,
          processPayment: function processPayment(token, cardData) {
            return _processPayment(id, token, cardData);
          },
          errors: errors,
          onError: onError
        }));

      case PROVIDERS.ADYEN.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayAdyenInput",
          name: "payment-method",
          value: "adyen",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayAdyenName"
        }, name))), checked && React.createElement(AdyenPaymentGateway, {
          config: config,
          formRef: formRef,
          scriptConfig: PROVIDERS.ADYEN.script,
          styleConfig: PROVIDERS.ADYEN.style,
          processPayment: function processPayment() {
            return _processPayment(id);
          },
          submitPayment: submitPayment,
          submitPaymentSuccess: submitPaymentSuccess,
          errors: errors,
          onError: onError
        }));

      default:
        return null;
    }
  }), !selectedPaymentGateway && errors && React.createElement(ErrorMessage, {
    errors: errors
  }));
};

export { PaymentGatewaysList };