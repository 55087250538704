import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject16() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  font-weight: ", ";\n  text-transform: uppercase;\n\n  &:hover {\n    color: ", ";\n  }\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  padding: 0;\n  white-space: nowrap;\n\n  li {\n    margin-left: 2rem;\n  }\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  padding-left: 1rem;\n\n  li {\n    line-height: 0;\n  }\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-right: 2rem;\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  border-left: 1px solid ", ";\n  display: flex;\n  height: 100%;\n  align-items: center;\n  padding: 0 ", ";\n  transition: all 0.3s;\n\n  path {\n    transition: 0.3s;\n  }\n\n  &:hover {\n    color: ", ";\n    path {\n      fill: ", ";\n    }\n  }\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  margin: 0 ", ";\n\n  path {\n    transition: 0.3s;\n  }\n\n  &:hover {\n    path {\n      fill: ", ";\n    }\n  }\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n      height: 30px;\n    "]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n      width: 4rem;\n    "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  line-height: 0;\n\n  svg {\n    width: 6rem;\n\n    ", "\n\n    ", "\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  justify-content: flex-end;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    flex-basis: calc(50% - 2rem);\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  flex-basis: calc(50% - 3rem);\n  overflow: hidden;\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  border-bottom: 1px solid ", ";\n  display: flex;\n  justify-content: space-between;\n  height: ", ";\n  position: relative;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
import ReactSVG from "react-svg";
export var NAVBAR_HEIGHT = "5rem";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.colors.white;
}, function (props) {
  return props.theme.colors.light;
}, NAVBAR_HEIGHT);
export var Tile = styled.div(_templateObject2(), media.largeScreen(_templateObject3()));
export var Navigation = styled(Tile)(_templateObject4());
export var Center = styled.div(_templateObject5());
export var Actions = styled(Tile)(_templateObject6());
export var LogoWrapper = styled(ReactSVG)(_templateObject7(), media.largeScreen(_templateObject8()), media.largeScreen(_templateObject9()));
export var IconWrapper = styled.button(_templateObject10(), function (props) {
  return props.theme.spacing.spacer;
}, function (props) {
  return props.theme.colors.primary;
});
export var SearchButton = styled.button(_templateObject11(), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.theme.spacing.spacer;
}, function (props) {
  return props.theme.colors.primary;
}, function (props) {
  return props.theme.colors.primary;
});
export var Text = styled.span(_templateObject12(), function (props) {
  return props.theme.typography.baseFontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
});
export var Mobile = styled.ul(_templateObject13());
export var Desktop = styled.ul(_templateObject14());
export var Button = styled.button(_templateObject15(), function (_ref) {
  var theme = _ref.theme;
  return theme.typography.baseFontSize;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.typography.boldFontWeight;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.primary;
});
export var NestedLink = styled.button(_templateObject16());