import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query SearchResults(\n    $query: String!\n    $languageCode: LanguageCodeEnum!\n    $sortBy: ProductOrder\n  ) {\n    products(filter: { search: $query }, first: 20, sortBy: $sortBy) {\n      edges {\n        node {\n          id\n          name\n          translation(languageCode: $languageCode) {\n            name\n            descriptionJson\n          }\n          thumbnail {\n            url\n            alt\n          }\n          thumbnail2x: thumbnail(size: 510) {\n            url\n          }\n          category {\n            id\n            name\n            translation(languageCode: $languageCode) {\n              name\n              descriptionJson\n            }\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n        hasPreviousPage\n        startCursor\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../../core/queries";
var searchResultsQuery = gql(_templateObject());
export var TypedSearchResults = TypedQuery(searchResultsQuery);