import classNames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import { TaxedMoney } from "@components/containers";
import { Thumbnail } from "@components/molecules";
import noPhotoImg from "images/no-photo.svg";
import { isEmpty } from "lodash";
import { generateProductUrl } from "../../core/utils";

var ProductRow = function ProductRow(_ref) {
  var mediumScreen = _ref.mediumScreen,
      processing = _ref.processing,
      line = _ref.line;
  return React.createElement("tr", {
    className: classNames({
      "cart-table-row--processing": processing
    })
  }, React.createElement("td", {
    className: "cart-table__thumbnail"
  }, React.createElement("div", null, mediumScreen && !isEmpty(line.product) ? React.createElement(Link, {
    to: generateProductUrl(line.product.id, line.product.name)
  }, React.createElement(Thumbnail, {
    source: line.product
  })) : React.createElement("img", {
    src: noPhotoImg,
    alt: ""
  }), !isEmpty(line.product) ? React.createElement(Link, {
    to: generateProductUrl(line.product.id, line.product.name)
  }, line.product.name) : line.name)), mediumScreen && React.createElement("td", null, !isEmpty(line.product) ? React.createElement(TaxedMoney, {
    taxedMoney: line.pricing.price
  }) : React.createElement(TaxedMoney, {
    taxedMoney: line.totalPrice
  })), !isEmpty(line.product) ? React.createElement("td", null, line.attributes.map(function (_ref2, attributeIndex) {
    var attribute = _ref2.attribute,
        values = _ref2.values;
    return React.createElement("p", {
      key: attribute.id
    }, attribute.name, ": ", values.map(function (value) {
      return value.name;
    }).join(", "));
  })) : React.createElement("td", null), React.createElement("td", {
    className: "cart-table__quantity-cell"
  }, React.createElement("p", null, line.quantity)), React.createElement("td", {
    colSpan: 2
  }, React.createElement(TaxedMoney, {
    taxedMoney: line.totalPrice
  })));
};

export default ProductRow;