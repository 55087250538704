import _objectDestructuringEmpty from "@babel/runtime/helpers/objectDestructuringEmpty";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ThankYou } from "@components/organisms";
import { BASE_URL } from "@temp/core/config";
import { generateGuestOrderDetailsUrl } from "@utils/core";

var ThankYouPage = function ThankYouPage(_ref) {
  _objectDestructuringEmpty(_ref);

  var location = useLocation();
  var history = useHistory(); // get token by splitting / character from url

  var arr = location.pathname.split("/");
  var token = arr[2]; // clear payment and checkout data after completing payment

  localStorage.setItem("data_checkout", "");
  localStorage.setItem("data_payment", "");
  return React.createElement(ThankYou, {
    continueShopping: function continueShopping() {
      return history.push(BASE_URL);
    },
    orderDetails: function orderDetails() {
      return history.push(generateGuestOrderDetailsUrl(token));
    }
  });
};

export { ThankYouPage };