import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n    height: 160px;\n    > img {\n      height: 160px;\n    }\n  "]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n    height: 230px;\n    > img {\n      height: 230px;\n    }\n  "]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 250px;\n\n  > img {\n    width: 100%;\n    height: 250px;\n    object-fit: cover;\n  }\n\n  ", "\n\n  ", "\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  text-align: center;\n  color: #317796;\n  min-height: 1.5rem;\n  ", "\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n    height: 2.4rem;\n    margin: 16px 4px 4px 4px;\n    overflow: hidden;\n  "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n    height: 2.4rem;\n    overflow: hidden;\n  "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n  font-weight: normal;\n  text-align: center;\n  font-weight: bold;\n  height: 2.4rem;\n  overflow: hidden;\n  vertical-align: middle;\n  margin: 16px 16px 4px 16px;\n  ", "\n  ", "\n  ", "\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n    padding: 0.3rem;\n    height: 340px;\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    padding: 1.0rem;\n    height: 440px;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  background: ", ";\n  height: 440px;\n  text-align: center;\n  transition: 0.3s;\n  // box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.08);\n\n  :hover {\n    background-color: ", ";\n  }\n\n  ", "\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
import { css } from "styled-components";
var textProps = css(_templateObject(), function (props) {
  return props.theme.typography.baseFontSize;
});
export var Wrapper = styled.div(_templateObject2(), function (props) {
  return props.theme.colors.white;
}, function (props) {
  return props.theme.colors.productCardBackgroundHover;
}, media.largeScreen(_templateObject3()), media.smallScreen(_templateObject4()));
export var Title = styled.h4(_templateObject5(), textProps, media.largeScreen(_templateObject6()), media.smallScreen(_templateObject7()));
export var Price = styled.p(_templateObject8(), textProps);
export var Image = styled.div(_templateObject9(), media.largeScreen(_templateObject10()), media.smallScreen(_templateObject11()));