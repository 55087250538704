import * as React from "react";
import { isEqual } from "lodash";
import { TaxedMoney } from "../../containers";
import * as S from "./styles";
/**
 * Renders formatted price for chosen variant or product.
 * Price ranges and discounts are additionally formatted available.
 */

export var getProductPrice = function getProductPrice(productPricingRange, variantPricing) {
  if (variantPricing) {
    if (isEqual(variantPricing.priceUndiscounted, variantPricing.price)) {
      return React.createElement(TaxedMoney, {
        taxedMoney: variantPricing.price
      });
    }

    return React.createElement(React.Fragment, null, React.createElement(S.UndiscountedPrice, null, React.createElement(TaxedMoney, {
      taxedMoney: variantPricing.priceUndiscounted
    })), React.createElement(TaxedMoney, {
      taxedMoney: variantPricing.price
    }));
  }

  if (!productPricingRange.priceRange) {
    return React.createElement(React.Fragment, null);
  }

  var _productPricingRange$ = productPricingRange.priceRange,
      start = _productPricingRange$.start,
      stop = _productPricingRange$.stop;

  if (isEqual(start, stop)) {
    return React.createElement(TaxedMoney, {
      taxedMoney: start
    });
  }

  return React.createElement(React.Fragment, null, React.createElement(TaxedMoney, {
    taxedMoney: start
  }), " - ", React.createElement(TaxedMoney, {
    taxedMoney: stop
  }));
};
export var canAddToCart = function canAddToCart(items, isAvailableForPurchase, variantId, variantStock, quantity) {
  var cartItem = items === null || items === void 0 ? void 0 : items.find(function (item) {
    return item.variant.id === variantId;
  });
  var syncedQuantityWithCart = cartItem ? quantity + ((cartItem === null || cartItem === void 0 ? void 0 : cartItem.quantity) || 0) : quantity;
  return isAvailableForPurchase && quantity > 0 && !!variantId && variantStock >= syncedQuantityWithCart;
};
/**
 * Returns how many items you can add to the cart. Takes in account quantity already in cart.
 */

export var getAvailableQuantity = function getAvailableQuantity(items, variantId, variantStock) {
  var cartItem = items === null || items === void 0 ? void 0 : items.find(function (item) {
    return item.variant.id === variantId;
  });
  var quantityInCart = (cartItem === null || cartItem === void 0 ? void 0 : cartItem.quantity) || 0;
  return variantStock - quantityInCart;
};