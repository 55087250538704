import _objectSpread from "@babel/runtime/helpers/objectSpread";
import "./scss/index.scss";
import * as React from "react";
import { useLanguage } from "gqlTypes/globalTypes";
import { MetaWrapper, NotFound } from "../../components";
import { STATIC_PAGES } from "../../core/config";
import { generatePageUrl, maybe } from "../../core/utils";
import Page from "./Page";
import { TypedArticleQuery } from "./query";

var canDisplay = function canDisplay(page) {
  return maybe(function () {
    return !!page && !!page.title && !!page.contentJson;
  });
};

var getHeaderImage = function getHeaderImage(shop) {
  return maybe(function () {
    return shop.homepageCollection.backgroundImage.url;
  });
};

var language = useLanguage();
export var View = function View(_ref) {
  var slug = _ref.match.params.slug;
  return React.createElement(TypedArticleQuery, {
    loaderFull: true,
    variables: {
      slug: slug,
      languageCode: language.toUpperCase()
    },
    errorPolicy: "all"
  }, function (_ref2) {
    var data = _ref2.data;
    var navigation = STATIC_PAGES.map(function (page) {
      return _objectSpread({}, page, {
        active: page.url === window.location.pathname
      });
    });
    var page = data.page,
        shop = data.shop;

    if (canDisplay(page)) {
      var _page$translation, _page$translation2, _page$translation3, _page$translation4;

      var breadcrumbs = [{
        link: generatePageUrl(slug),
        value: page.translation && ((_page$translation = page.translation) === null || _page$translation === void 0 ? void 0 : _page$translation.title) ? (_page$translation2 = page.translation) === null || _page$translation2 === void 0 ? void 0 : _page$translation2.title : page.title
      }];
      return React.createElement(MetaWrapper, {
        meta: {
          description: page.translation && ((_page$translation3 = page.translation) === null || _page$translation3 === void 0 ? void 0 : _page$translation3.seoDescription) ? page.translation.seoDescription : page.seoDescription,
          title: page.translation && ((_page$translation4 = page.translation) === null || _page$translation4 === void 0 ? void 0 : _page$translation4.seoTitle) ? page.translation.seoTitle : page.seoTitle
        }
      }, React.createElement(Page, {
        breadcrumbs: breadcrumbs,
        headerImage: getHeaderImage(shop),
        navigation: navigation,
        page: data.page
      }));
    }

    if (page === null) {
      return React.createElement(NotFound, null);
    }
  });
};
export default View;