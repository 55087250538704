import React from "react";
import { TaxedMoney } from "@components/containers";
import { Thumbnail } from "@components/molecules";
import { Button } from "@temp/components";
import { FormattedMessage } from "react-intl";
import isEqual from "lodash/isEqual";
import * as S from "./styles";
export var ProductTile = function ProductTile(_ref) {
  var product = _ref.product;
  var price = product.pricing && product.pricing.priceRange && product.pricing.priceRange.start ? product.pricing.priceRange.start : undefined;
  var undiscountedPrice = product.pricing && product.pricing.priceRangeUndiscounted && product.pricing.priceRangeUndiscounted.start ? product.pricing.priceRangeUndiscounted.start : undefined;
  return React.createElement(S.Wrapper, null, React.createElement(S.Image, {
    "data-test": "productThumbnail"
  }, React.createElement(Thumbnail, {
    source: product
  })), React.createElement(S.Title, {
    "data-test": "productTile"
  }, product.translation ? product.translation.name : product.name), React.createElement(S.Price, {
    "data-test": "productPrice"
  }, React.createElement("span", {
    className: "product-list-item__undiscounted_price"
  }, !isEqual(price, undiscountedPrice) && React.createElement(TaxedMoney, {
    taxedMoney: undiscountedPrice
  }))), React.createElement(S.Price, {
    "data-test": "productPrice"
  }, React.createElement(TaxedMoney, {
    taxedMoney: price
  })), React.createElement(Button, {
    testingContext: "productActionButton",
    productCard: true
  }, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.ProductTile.ProductTile.1456701921",
    defaultMessage: "Add to cart"
  })));
};