import { Formik } from "formik";
import React from "react";
import * as S from "./styles";

/**
 * Mashreq payment gateway.
 */
var MashreqPaymentGateway = function MashreqPaymentGateway(_ref) {
  var processPayment = _ref.processPayment,
      formRef = _ref.formRef,
      formId = _ref.formId,
      initialStatus = _ref.initialStatus;
  return React.createElement(Formik, {
    initialValues: {
      status: initialStatus || "charged"
    },
    onSubmit: function onSubmit(values, _ref2) {
      var setSubmitting = _ref2.setSubmitting;
      processPayment(values.status);
      setSubmitting(false);
    }
  }, function (_ref3) {
    var handleChange = _ref3.handleChange,
        handleSubmit = _ref3.handleSubmit,
        handleBlur = _ref3.handleBlur,
        values = _ref3.values,
        isSubmitting = _ref3.isSubmitting,
        isValid = _ref3.isValid;
    return React.createElement(S.Form, {
      id: formId,
      ref: formRef,
      onSubmit: handleSubmit,
      "data-test": "mashreqPaymentGatewayForm"
    });
  });
};

export { MashreqPaymentGateway };